<template>
  <master-detail
    has-year-filter
    :actionBarButtons="actionBarButtons"
    :canEdit="false"
    :canDelete="false"
    :cols="cols"
    :contextOptions="contextOptions"
    :customResource="customResource"
    :disableActContextOnClick="true"
    :expandSubItem="true"
    :hasNewButton="false"
    :opts="opts"
  ></master-detail>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {
    "master-detail": () => import("@/components/master-detail.vue"),
  },
  computed: {
    ...mapGetters(["clientId"]),
    actionBarButtons: function () {
      const buttons = [
        {
          text: "Adicionar",
          icon: "mdi-plus-box-outline",
          show: this.userHasAccessToAction('create'),
          action: () => {
            this.$router.push({ name: "cadastro-dispendio-equipamentos-softwares" });
          },
        },
      ];

      return buttons.filter(({ show }) => show);
    },
    cols: function () {
      return [
        // Dados da NFe
        {
          key: "id",
          name: "ID",
        },
        {
          key: "terceiro",
          name: "Fornecedor",
          type: this.$fieldTypes.TEXT,
          width: '300px',
        },
        {
          key: "nro_documento",
          name: "Nro do Documento",
          type: this.$fieldTypes.TEXT,
        },
        {
          key: "data",
          name: "Data de Emissão",
          type: this.$fieldTypes.DATE,
        },
        {
          key: "periodo_depreciacao",
          name: "Período Depreciação (em meses)",
          type: this.$fieldTypes.NUMBER,
          align: 0,
        },
        {
          key: "valor_nf",
          name: "Valor da Nota",
          type: this.$fieldTypes.MONEY,
          nowrap: true,
          align: 1,
        },
        // Dados dos Itens
        {
          key: "finalidade",
          name: "Classificação LI",
          type: this.$fieldTypes.TEXT,
          width: "250px",
        },
        {
          key: "descricao",
          name: "Descrição do Item",
          type: this.$fieldTypes.TEXT,
          width: '500px',
        },
        {
          key: "valor",
          name: "Valor do Item",
          type: this.$fieldTypes.MONEY,
          align: 1,
        },
        {
          key: "valor_deprec",
          name: "Valor Depreciação (calculado)",
          type: this.$fieldTypes.MONEY,
          nowrap: true,
          align: 1,
        },
        // Dados da depreciação
        {
          key: "projeto",
          name: "Projeto",
          type: this.$fieldTypes.TEXT,
          width: '300px',
        },
        {
          key: "competencia",
          name: "Competência",
          type: this.$fieldTypes.MONTH,
          align: 0,
        },
        {
          key: "justificativa",
          name: "Justificativa",
          type: this.$fieldTypes.TEXTAREA,
          width: '500px',
        },
      ];
    },
    contextOptions: function () {
      const options = [
        {
          name: "Editar",
          limit: 1,
          show: this.userHasAccessToAction('update'),
          cb: (row) => {
            this.$router.push({ name: "edicao-dispendio-equipamentos-softwares", params: { nfId: row.id }});
          },
        },
      ];

      return options.filter(({ show }) => show);
    },
    customResource: function () {
      const resource = this.apiResource(
        `/v1/fiscal/nfdepreciacao/${this.clientId}`
      );
      return {
        ...resource,
        get: (...args) => {
          return resource.get(...args).then((response) => {
            return !Array.isArray(response)
              ? []
              : response.map((nf) => {
                  const { itens, ...row } = nf;
                  const nested = itens.reduce((prev, item) => {
                    const { depreciacoes, ...itemData } = item;
                    const data = !Array.isArray(depreciacoes)
                      ? []
                      : depreciacoes.map((depreciacao) => ({
                          ...row,
                          ...itemData,
                          ...depreciacao,
                        }));
                    return [...prev, ...data];
                  }, []);
                  return [row, nested];
                });
          });
        },
      };
    },
  },
  data: function () {
    return {
      opts: {}
    };
  },
};
</script>
